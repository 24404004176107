<template>
  <div class="loader w-fit-full relative">
    <div class="loaderBar" />
  </div>
</template>

<script>
export default {
  name: 'LoadingBar'
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/components/loading-bar-styles.scss';
</style>
